<template>
  <div id="printOrder" v-if="form.transTruckId">
    <div>
      <h1 class="file-h1">
        托运清单 运单编号：{{ form.transTruckId }}
      </h1>
    </div>
    <div class="file-body">
      <div class="file-body-head">
        <did class="filingTime">
          归档时间：{{ form.fileTime }}
        </did>
        <did class="line">
          线路：{{ form.startAddress }} - {{ form.endAddress }}
        </did>
      </div>
      <div class="file-body-body">
        <table border="1" width="100%" style="text-align: center">
          <tbody>
            <tr>
              <td rowspan="1">
                序号
              </td>
              <td rowspan="1">
                合同编号
              </td>
              <td rowspan="1">
                客户
              </td>
              <td rowspan="1">
                业务员
              </td>
              <td colspan="1">
                起运地
              </td>
              <td colspan="1">
                目的地
              </td>
              <td colspan="1">
                车辆名称
              </td>
              <td colspan="1">
                车牌架号
              </td>
              <td rowspan="1">
                车到收
              </td>
           
              <td colspan="1">
                收车人
              </td>
              <td colspan="1">
                收车人电话
              </td>
              <td colspan="1">
                收车人证件
              </td>
              <td colspan="1">
                提车方式
              </td>
              <td colspan="1">
                提车地址
              </td>
              <td colspan="1">
                卸车时间
              </td>
              <td colspan="1">
                卸车人
              </td>
            </tr>
            <tr>
            </tr>
            <tr v-for="(content ,index) in form.details" :key="index">
              <td>
                {{ index+1 }}
              </td>
              <td>
                {{ content.id }}
              </td>
              <td>
                {{ content.customerName }}
              </td>
              <td>
                {{ content.salesman }}
              </td>
              <td>
                {{ content.startAddress }}
              </td>
              <td>
                {{ content.endAddress }}
              </td>
              <td>
                {{ content.brand }} {{ content.model }}
              </td>
              <td>
                {{ content.vinNo }}
              </td>
              <td>
                ￥ {{ content.paidAmt }}
              </td>
              <td>
                {{ content.picker }}
              </td>
              <td>
                {{ content.pickerMobile }}
              </td>
              <td>
                {{ content.pickerCertificateNumber }}
              </td>
              <td v-if="content.isTakeOut?.value === 0">
                自提
              </td>
              <td v-if="content.isTakeOut?.value === 1">
                送车
              </td>
              <td>
                {{ content.takeOutAddress }}
              </td>
              <td>
                {{ content.unloadingTime }}
              </td>
              <td>
                {{ content.unloadingOperator }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="file-body-foot">
        <div class="foot">司机：{{ form.driverName }} 电话:{{ form.driverTel }}</div>
        <div class="foot">经手人：____________</div>
        <div class="foot">经手人：____________ 年 ______ 月 ______ 日</div>
        <div class="foot line">制单：{{ form.former }}</div>
        <div class="foot line">{{ form.fileDate}}</div>
      </div>
    </div>
  </div>
  <a-space>
    <a-button @click="pdfDownload">清单下载</a-button>
  </a-space>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { vehicleTruckOnFile } from '@/api/transport/vehicle'
import html2canvas from 'html2canvas'
import { jsPDF as JsPDF } from 'jspdf'
// import {message} from "ant-design-vue";

export default {
  props: {
    // transTruckId: { type: String, default: '' },
    transTruckId: String
  },
  setup (props) {
    const state = reactive({
      visible: false,
      form: { transTruckId: '' },
      transTruckId: 0
    })

    const loadData = async (id) => {
      state.loading = true
      const { data } = await vehicleTruckOnFile(id)
      state.form = data
      state.loading = false
    }
    loadData(props.transTruckId)

    const pdfDownload = () => {
      html2canvas(document.querySelector('#printOrder')).then(canvas => {
        const contentWidth = canvas.width
        const contentHeight = canvas.height
        // 一页pdf显示html页面生成的canvas高度;
        const pageHeight = contentWidth / 592.28 * 841.89
        // 未生成pdf的html页面高度
        let leftHeight = contentHeight
        // 页面偏移
        let position = 20
        // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        const imgWidth = 555.28
        const imgHeight = 552.28 / contentWidth * contentHeight
        const pageData = canvas.toDataURL('image/jpeg', 1.0)
        const pdf = new JsPDF('', 'pt', 'a4')
        // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
        // 当内容未超过pdf一页显示的范围，无需分页
        if (leftHeight < pageHeight) {
          pdf.addImage(pageData, 'JPEG', 20, 0, imgWidth, imgHeight)
        } else {
          while (leftHeight > 0) {
            pdf.addImage(pageData, 'JPEG', 20, position, imgWidth, imgHeight)
            leftHeight -= pageHeight
            position -= 841.89
            // 避免添加空白页
            if (leftHeight > 0) {
              pdf.addPage()
            }
          }
        }
        pdf.save(`车辆归档-${state.form.transTruckId}.pdf`)
      })
    }
    return {
      loadData,
      ...toRefs(state),
      pdfDownload
    }
  }

}
</script>

<style lang="less" scoped>
#container {
  width: 100%;
  //height: 810px;
  margin: 0;
  font-family: '微软雅黑';
}

.mod-user {
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  width: 55%;
  display: flex;
  position: absolute;
  z-index: 999;
  left: 0%;
  max-height: 810px;
  overflow-x: hidden;
}

.data_button {
  width: 100%;
  text-align: center;
  border: 0;
}

.ransport_search_input {
  width: 187px;
}

.suy_bod_b {
  border-top: 1px solid #dddddd;
}

.suy {
  background: #fff;
}

.sform {
  margin-bottom: 16px;
  // margin-top: 16px;
  padding: 24px;
  background: #fff;
  border-radius: @border-radius-base;
}

.suy_body {
  height: 180px;
}

.suy_title {
  text-align: left;
  height: 40px;
  display: flex;
  line-height: 40px;
}

h2 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: bold;
}

.suy_title_p {
  font-size: 14px;
  color: #ff0101;
}

.textindent {
  text-indent: 14px;
}

.suy_table_title {
  // margin-left: 14px;
  // margin-right: 14px;
}

.suy_table_title_div {
  display: flex;
  height: 32px;
  width: 100%;
  border-top: 1px solid #eeeeee;
  // border-bottom: 1px solid #eeeeee;
}

.inoc_div {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
}

.suy_table_title_div_span {
  font-size: 14px;
  margin-left: 8px;
}

.suy_table_title_div_L {
  width: 32px;
  justify-content: flex-start;
  display: flex;
  align-items: center;
}

.suy_table_title_div_R {
  flex: 1;
  height: 32px;
  justify-content: flex-end;
  display: flex;
  padding-right: 14px;
  align-items: center;
}

.inoc_div > span {
  margin: 5px;
}

.suy_table_title_div_R_button {
  padding-right: 20px;
  height: 32px;
}

.input_a {
  width: 165px;
}

.input_b {
  width: 150px;
}

.input_c {
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: right;
  margin-right: 0px;
}

.input_d {
  width: 394px;
}

.ant-col-9 {
  margin: 0 !important;
}

.button_halfway {
  background-color: #007bff;
}

.button_arrive {
  border: 0px;
  background-color: green;
}

.button_fault {
  border: 0px;
  background-color: orange;
}
.file-h1 {
  width: 100%;
  text-align: center;
}
.file-body {
  width: 96%;
  margin: 0 auto;
}
.file-body-head {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}
.filingTime {
  margin-right: 10px;
}
.line {
  flex: 1;
  text-align: right;
}
.file-body-foot {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}
.foot {
  width: 20%;
}
</style>
